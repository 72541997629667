import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Head from 'components/head';
import SectionMain from 'components/section-main';
import SectionMainHead from 'components/section-main-head';
import Title from 'components/title';
import CtaButton from 'components/ctabutton';
import List from 'components/list';
import {
  AsideFull,
  AsideWithTwoImages,
  AsideWithGraph,
  AsideInBgSection,
} from 'components/section-main/aside.css';
import COLORS from 'constants/colors';
import PatternTriangle4Inline from 'images/pattern-triangle4-inline.svg';
import PatternTriangle from 'images/pattern-triangle-iso.svg';
import PatternCircual from 'images/pattern-circual.svg';
import PatternSquareBlu from 'images/pattern-square-blu.svg';
import PatternRound from 'images/pattern-round.svg';
import PatternTriangleOblique from 'images/pattern-triangle3-oblique.svg';
import PatternTripleTriangle from 'images/pattern-triple-triangle-white.svg';
import PatternTripleTrianglePink from 'images/pattern-triple-triangle-pink.svg';
import { gridTemplateColumns } from 'constants/grid';

const Scuola = ({ data }) => {
  const PAGE = data.scuolaJson;
  const VALORI = data.scuolaJson.sections[0];
  const LOCATION = data.scuolaJson.sections[1];
  const FEATURES = data.scuolaJson.sections[2];
  const SUCCESSI = data.scuolaJson.sections[3];

  return (
    <>
      <Head pageTitle={PAGE.title} />

      <SectionMainHead data={PAGE} />

      <SectionMain>
        <Title as="h2" color="green">
          {VALORI.title}
        </Title>

        <div
          className="offsetRight-tablet"
          dangerouslySetInnerHTML={{
            __html: VALORI.content.childMarkdownRemark.html,
          }}
        />

        <AsideWithGraph theme={{ align: 'right' }}>
          <PatternCircual fill={COLORS.pink} />
          <Img fluid={VALORI.aside.image.childImageSharp.fluid} />
          <PatternTriangle4Inline fill={COLORS.lightBlue} />
          <PatternTriangle fill={COLORS.pink} />
        </AsideWithGraph>
      </SectionMain>

      <SectionMain
        bgColor="darkGreen"
        theme={{ gridColumns: gridTemplateColumns.col31 }}
      >
        <List className="doubleColumn" list={FEATURES.list} textColor="white" />
        <AsideInBgSection>
          <PatternTripleTriangle />
          <Img fluid={FEATURES.aside.image.childImageSharp.fluid} />
        </AsideInBgSection>
      </SectionMain>

      <SectionMain
        theme={{
          asideAlign: 'left',
        }}
      >
        <Title as="h2" color="green">
          {LOCATION.title}
        </Title>

        <Title as="h3" size="extrasmall" color="blue" className="offsetRight">
          {LOCATION.subTitle}
        </Title>

        <div
          className="offsetRight-tablet"
          dangerouslySetInnerHTML={{
            __html: LOCATION.content.childMarkdownRemark.html,
          }}
        />

        <CtaButton linkTo="/contatti" fontWeight="bold" style="primary-green">
          {LOCATION.cta}
        </CtaButton>

        <AsideWithTwoImages>
          <PatternSquareBlu />
          <Img fluid={LOCATION.aside.image.childImageSharp.fluid} />
          <Img fluid={LOCATION.aside.imagesecondary.childImageSharp.fluid} />
          <PatternTriangleOblique />
          <PatternRound />
        </AsideWithTwoImages>
      </SectionMain>

      <SectionMain
        bgColor="blue"
        bgPattern={true}
        theme={{ gridColumns: gridTemplateColumns.col48 }}
      >
        <PatternSquareBlu />
        <Title as="h2" color="pink" bordered={true} size="small">
          {SUCCESSI.title}
        </Title>

        <AsideFull>
          <List
            list={SUCCESSI.list}
            textColor="white"
            colorUnderline="darkGreen"
          />
        </AsideFull>
        <PatternTripleTrianglePink />
      </SectionMain>
    </>
  );
};

Scuola.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Scuola;

export const query = graphql`
  query ScuolaQuery {
    scuolaJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      sections {
        title
        subTitle
        cta
        content {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        aside {
          image {
            childImageSharp {
              fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imagesecondary {
            childImageSharp {
              fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        list {
          title
          subTitle
        }
      }
    }
  }
`;
